* {
  box-sizing: border-box;
}

.flip-card {
  background-color: transparent;
  width: 70%;
  height: 50vh;
  perspective: 1000px;
  border-radius: 10px;
}

@media only screen and (max-width: 432px) {
  /*Small smartphones [325px -> 425px]*/
  .flip-card {
    background-color: transparent;
    width: 90%;
    height: 53vh;
    perspective: 1000px;
    border-radius: 10px;
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  /* transition: transform 0.6s; */
  transform-style: preserve-3d;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* .flip-card:hover .flip-card-inner {
  transform: rotateX(180deg);
} */

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #f3f7fb;
  border-radius: 10px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-back {
  background-color: #f3f7fb;
  padding: 10px;
  color: black;
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.logos {
  width: 100%;
}
.logos img {
  width: 20%;
  padding: 30px;
  height: 20vh;
  aspect-ratio: 3/2;
  object-fit: contain;
}

@media only screen and (max-width: 432px) {
  /*Small smartphones [325px -> 425px]*/
  .logos {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .logos img {
    width: 100%;
    /* padding: 30px;
    height: 20vh;
    aspect-ratio: 3/2;

    object-fit: contain; */
  }
}

/* header css */
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.underline-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: #485687;
  text-decoration: none;
  padding-bottom: 2px;
}

.underline-hover::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background: #2b57f4;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.underline-hover:hover::before {
  width: 100%;
}

.underline-hover.selected::before {
  width: 100%;
  background: #2b57f4;
}
